// import {highChart} from "../../../vendor/highchart/highchart.js";
import TomSelect from "../../../vendor/tomselect/js/tomselect.js";
var Highcharts = require('highcharts');
// const { highChart } = require('../../../vendor/highchart/highchart.js')
TomSelect

const fdCalculator = (chartData) => {
    let data;
    if (chartData){
        // console.log('chartData change ');
        data=chartData;
    }else{
        data = {
            'earnings': 100,
            'investAmount':0
          }
    }


// pie chart
// highChart()
const options = {
    chart: {
        type: 'pie',
        backgroundColor: 'transparent',
        plotWidth: 150, // Set plot width in pixels
    plotHeight: 150, // Set plot height in pixels
        // borderWidth: 0,
        // width: '100%',
        // width: document.querySelector('[data-donut-chart]').offsetWidth, // Set the width of the chart to 400 pixels
        // height: document.querySelector('[data-donut-chart]').offsetHeight, // Set the height of the chart to 300 pixels
        // aspectRatio: 2 // Maintain aspect ratio of 4:3
        margin: [0, 0, 0, 0],
        spacing: [20, 20, 20, 20],
        // plotLeft: 0, // Set the left margin of the plot area
        // plotTop: 0, // Set the top margin of the plot area
        // plotWidth: 580, // Set the width of the plot area
        // plotHeight: 380 // Set the height of the plot area
        events: {
            load: function () {
              var chart = this;
              setTimeout(function () {
                if(chart.series) {
                    var gElement = chart.series[0].group.element;
                    gElement.setAttribute('width', '100%');
                    gElement.setAttribute('height', '100%');
                }
              }, 100); // Adjust the delay as needed
            }
          }
    },
    title: {
        text: "",
        // color: '#333333',
    },
    legend: {
        enabled: false,
        symbolWidth: 25, // Adjust the width of legend symbols (rectangular pills)
        symbolHeight: 12, // Adjust the height of legend symbols
        backgroundColor: 'none'
    },
    plotOptions: {
        pie: {
            // backgroundColor: 'transparent',
            innerSize: '70%', // Set innerSize to create a donut chart
            allowPointSelect: false,
            cursor: 'pointer',
            borderRadius: 0,
            borderColor: 'transparent',
            // dataLabels: {
            //     connectorWidth: 0,
            //     connectorPadding: 0,
            //     backgroundColor: 'transparent'
            // }
        //     dataLabels: {
        //         enabled: true,
        //         distance: '-100%',
        //         formatter: function() {
        //             // Style for name text
        //             var nameStyle = 'font-weight: bold; font-size: 8px; color: #1F1F1F; margin: 0; padding: 0;';
        //             // Style for percentage text
        //             var percentStyle = 'font-weight: 700; font-size: 16px; color: #1F1F1F;';
                
        //             return '<div style="white-space: normal; min-width: 100px; overflow: hidden; text-align: center;">' +
        //                        '<span style="' + nameStyle + '">' + this.point.name + '</span>' +
        //                        '<br>' +
        //                        '<span style="' + percentStyle + '">' + Highcharts.numberFormat(this.percentage, 1) + '%</span>' +
        //                    '</div>';
        //         },
                
        //     style: {
        //         textOverflow: 'none', // Prevent text from being truncated
        //         overflow: 'auto' // Allow text to overflow
        //     }
        // },

        },
    },
    series: [
        {
            name: 'Investment Summary',
            // showInLegend: true,
            // colorByPoint: true,
            data: [
                {
                    showInLegend: false,
                    name: 'Total Earnings',
                    y: data.earnings,
                    selected: true,
                },
                {
                    showInLegend: false,
                    name: 'Invested Amount',
                    y: data.investAmount,
                    selected: true,

                }
            ],
            dataLabels: {
                enabled: false,
                style: {
                  textOutline: '2px contrast' // Set initial text outline style
                }
              }
        },
    ],
    credits: {
        enabled: false
    },
    tooltip: { enabled: false },
};

const pieChart = Highcharts.chart(document.querySelector('[data-donut-chart]'), options);
// Get the SVG element of the series group
var seriesGroup = pieChart.series[0].group.element;

// Apply scale and translation transformations
seriesGroup.setAttribute('style', 'width: 100%');

var gradientColors = [
    ['#eaeaea', '#eaeaea'],
    ['#6100FF', '#FDB1FF'], // Example gradient for first slice
     // Example gradient for second slice
    // Add more gradients for other slices as needed
];

// Assuming gradientColors is an array of arrays containing color values
for (var i = 0; i < gradientColors.length; i++) {
    var gradient = pieChart.renderer.createElement('linearGradient');
    gradient.attr({
        id: 'gradient-' + i,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1
    });
    pieChart.renderer.defs.element.appendChild(gradient.element);

    gradientColors[i].forEach(function (color, j) {
        var stop = pieChart.renderer.createElement('stop');
        stop.attr({
            offset: j / (gradientColors[i].length - 1),
            'stop-color': color
        });
        gradient.element.appendChild(stop.element);
    });

    // Apply pattern to each slice
    pieChart.series[0].data[i].update({
        color: 'url(#gradient-' + i + ')'
    });
}

}
const rangeSlider = () => {
    //range slider
const rangeInput = document.querySelector('input[type="range"]')
const numberInput = document.querySelector('[data-range]')

// rangeInput.forEach(element => {
//     element.addEventListener('input',function(e) {
//         const content = document.querySelector(`[data-range=${e.target.id}]`)
//         content.innerText = e.target.value
//     })
// })
// const sliderEl = document.querySelector("#range")
rangeInput?.addEventListener("input", (event) => {
    const content = document.querySelector(`[data-range=${event.target.id}]`)
console.log(event.target.value,event.target)


// if(event.target.value > 5000 && event.target.value < 10000) {
//     console.log('if 2')
//     // event.target.setAttribute("min", "5000")
//     event.target.setAttribute("step", "1000")
//     event.target.setAttribute("max", "10000")
// }

if(event.target.value >= 5000 && event.target.value < 50000) {
    event.target.setAttribute("max", "50000")
    console.log('if31')
}

else if(event.target.value > 49999 && event.target.value < 1000000) {
    event.target.setAttribute("max", "1000000")
    console.log('if 4')
}
else if(event.target.value > 199999 && event.target.value < 10000000){
    event.target.setAttribute("max","10000000")
}
console.log(content.value)
            content.value = event.target.value;
            const tempSliderValue = event.target.value;
            const minValue = parseFloat(rangeInput?.min); // Get the minimum value
            const maxValue = parseFloat(rangeInput?.max); // Get the maximum value
            const range = maxValue - minValue; // Calculate the range
            
            // Calculate the progress based on both minimum and maximum values
            const progress = ((tempSliderValue - minValue) / range) * 100;
            
            // Apply the gradient to the slider background
            rangeInput &&(rangeInput.style.background = `linear-gradient(to right, #272AB8 ${progress}%, #EAEAEA ${progress}%)`);
            })


numberInput?.addEventListener('input', (event) => {
rangeInput && (rangeInput.value = event.target.value);
console.log(event.target.value)
// rangeInput.setAttribute("max","10000000")
if(event.target.value >= 5000 && event.target.value < 50000) {
    rangeInput?.setAttribute("max", "50000")
    console.log('if31')
}

else if(event.target.value > 999 && event.target.value < 1000000) {
    rangeInput?.setAttribute("max", "1000000")
    console.log('if 4')
}
else if(event.target.value > 9999 && event.target.value < 10000000){
    rangeInput?.setAttribute("max","10000000")
}

            const tempSliderValue = event.target.value;
            const minValue = parseFloat(rangeInput?.min); // Get the minimum value
            const maxValue = parseFloat(rangeInput?.max); // Get the maximum value
            const range = maxValue - minValue; // Calculate the range
            
            // Calculate the progress based on both minimum and maximum values
            const progress = ((tempSliderValue - minValue) / range) * 100;

  rangeInput && (rangeInput.style.background = `linear-gradient(to right, #272AB8 ${progress}%, #EAEAEA ${progress}%)`);
})




//Reset select input when the form is reset
const fdForm = document.querySelector('[data-fd-form]')

fdForm?.addEventListener('reset', function() {
    payout.setValue(defaultSelectedValues)
    rangeInput && (rangeInput.style.background='#EAEAEA')
})
//select
const payout = new TomSelect('#payout', {
    controlInput: null,
    labelField: "enter name"
});
let defaultSelectedValues = payout.getValue();

//console.log(payout)

}
export default {fdCalculator,rangeSlider}